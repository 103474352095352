@import '~antd/dist/antd.css';
@import './assets/iconFonts/material-design-iconic-font.css';
@import './constants/cssConstants.scss';
@import '~react-datepicker/dist/react-datepicker.css';

html {
  box-sizing: border-box;
  height: 100%;
  font-size: 14px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Istok Web', sans-serif;
  background-color: #fff;
  font-size: 14px;
  overflow: hidden !important;
}

#root {
  height: 100vh;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

table {
  span,
  td,
  th,
  tr {
    font-size: 1rem;
  }
}

button {
  border-radius: 4px !important;

  span {
    font-size: 1rem;
    line-height: 1rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  font-size: 1rem;
}

.ant-modal-content {
  border-radius: 12px !important;
}

.ant-modal-header {
  border-radius: 12px 12px 0 0 !important;
  text-align: center !important;
  color: #545454 !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #398dc4 !important;
}

.ant-radio-inner::after {
  background-color: #398dc4 !important;
}

.ant-tabs-tab:hover {
  color: #398dc4 !important;
}

.ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-tabs-ink-bar {
  background: #15589a !important;
}

.ant-form.ant-form-horizontal {
  width: 100% !important;
}

.ant-select-selector {
  border-radius: 6px !important;
  background-color: #f5f5f5 !important;
  height: 30px !important;
  font-size: 1rem !important;
}

.p-multiselect {
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
  background-color: #f5f5f5 !important;
  height: 30px !important;
  font-size: 1rem !important;
}

.ant-select-disabled,
.ant-picker-disabled {
  opacity: 0.6 !important;
  cursor: default !important;

  input,
  .ant-picker-separator,
  .ant-select-selector {
    cursor: default !important;
  }
}

.ant-select-selector {
  .ant-select-selection-placeholder {
    font-size: 1rem !important;
  }
}

.ant-select-selection-item {
  border-radius: 6px !important;
  background-color: #f5f5f5 !important;
  line-height: 30px !important;
}

.ant-select-selection-item-content,
.ant-select-selection-item-remove {
  line-height: 22px !important;
}

.ant-select-item-option-active {
  font-weight: bold;
}

.ant-input:placeholder-shown {
  font-size: 1rem !important;
}

.react-datepicker__triangle {
  left: 20px !important;
}

.react-datepicker__day--selected {
  background-color: #038fde !important;
}

.react-datepicker__day--selected:hover {
  background-color: #28aaeb !important;
}

.ant-table-pagination-right {
  float: none;
  display: flex;
  justify-content: flex-end;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-item-link {
  border-radius: 5px !important;
}

.ant-pagination-item-active {
  border-color: $blue-text-color;
}

.ant-table {
  border-radius: 6px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 1rem;
  color: $grey-text-color;
}

.ant-table-title {
  border-bottom: 1px solid #e8e8e8;
}

.ant-tabs-tab {
  font-size: 1rem !important;
}

.ant-card {
  border-radius: 6px !important;
  margin-bottom: 30px !important;
}

.ant-card-body {
  padding: 16px 24px !important;
}

.ant-checkbox-inner {
  border-radius: 6px !important;
}

/* leeds table edit */
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

/* leeds table edit end */

.react-datepicker-popper {
  z-index: 100;
}

.status-counter-text {
  border-bottom: 2px solid transparent;
  transition: border 0.4s !important;
}

.ant-radio-button-wrapper-checked .status-counter-text,
.ant-radio-button-wrapper:hover .status-counter-text {
  color: $link-text !important;
  border-bottom: 2px solid $link-text;
}

th.ant-table-cell {
  transition: background-color 0.3s ease !important;
}

th.ant-table-cell:hover {
  background-color: #e5e5e5 !important;
}

/* start calendar styles */
.fc-daygrid-day-number {
  font-size: 1rem;
  color: #545454;
}

.fc-col-header-cell.fc-day.fc-day-sat .fc-scrollgrid-sync-inner {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 20px !important;
}

.fc-col-header-cell-cushion {
  font-size: 1rem;
  color: #545454;
  font-weight: 900;
}

.fc-scrollgrid.fc-scrollgrid-liquid {
  background-color: #fafafa !important;
}

.fc-list-empty-cushion,
.fc-timegrid-axis.fc-scrollgrid-shrink,
.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink,
.fc-list-event-time,
.fc-list-day-text {
  color: #545454;
}

.fc-col-header-cell.fc-day {
  border: none !important;
  border-bottom: 1px solid #ddd !important;
}

.fc-col-header-cell-cushion {
  font-size: 12px;
  line-height: 128.91%;
  text-transform: uppercase;
  margin: 10px 0;
  color: #1e496b !important;
}

.fc-daygrid-day-number {
  color: #1e496b !important;
}

.fc-col-header-cell.fc-day.fc-day-sat {
  padding: 9px !important;
}

.fc-daygrid-day.fc-day.fc-day-wed.fc-day-today {
  background-color: #eaf6fe !important;
}

.fc-daygrid-day.fc-day.fc-day-wed.fc-day-today .fc-daygrid-day-number {
  font-weight: bold !important;
}

.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  cursor: pointer;
  padding: 10px;
}

.fc-day-today {
  background-color: #e6eff8 !important;
}

.react-datepicker__month {
  margin: 0 !important;
}

.react-datepicker__day--selected,
.react-datepicker__month--selected {
  background-color: #0070b9 !important;
}

.react-datepicker__time-box,
.react-datepicker__time-container {
  width: 70px !important;
}

.react-datepicker__tab-loop__end {
  display: none !important;
}

/* end calendar styles */

.ant-select-multiple .ant-select-selector {
  overflow-y: scroll !important;

  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent !important;
  }
}

/*----ckeditor start----*/

.ck-editor__editable {
  min-height: 100px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/*----ckeditor end----*/

.standard-btn,
.ant-table-filter-dropdown-btns .ant-btn-primary {
  border-radius: 5px !important;
  border-color: $blue-text-color !important;
  background-color: $blue-text-color !important;
  transition: background-color 0.2s !important;

  &:hover {
    background-color: $button-hover-color !important;
    border-color: $button-hover-color !important;
  }
}

/*----idcard icon start----*/

.anticon.anticon-idcard {
  display: flex;
  align-items: center;
}

/*----idcard icon end----*/

.ant-pagination-item-active {
  border-color: $link-text !important;

  a {
    color: $link-text !important;
  }
}

/*----primereact styles start---*/

.p-multiselect {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  font-size: 1rem !important;
  border-radius: 5px !important;
  background-color: #f5f5f5 !important;
}

.p-multiselect-label.p-placeholder {
  color: #c0c0c0 !important;
  font-size: 1rem;
}

.pi-chevron-down.p-c::before {
  color: #c3c3c3 !important;
  font-size: 1rem;
}

.p-multiselect-item {
  height: 35px;
  font-size: 1rem !important;
}

.p-multiselect-header {
  height: 35px;
}

.p-inputtext.p-component.p-multiselect-filter {
  height: 30px;
}

.p-fieldset-legend.p-unselectable-text {
  background-color: #2670b9 !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;

  a {
    color: #fff !important;
    outline: none !important;
    border: none !important;
    padding: 13px !important;
  }
}

.p-fieldset-content {
  padding-top: 0 !important;
}

.ant-collapse-header {
  padding: 0 !important;
}

.ant-collapse {
  border-radius: 5px !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  padding: 0 !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child a {
  padding: 0 !important;
  margin: 0 !important;
}

/*----primereact styles start----*/

.ant-picker-range {
  input::placeholder {
    font-size: 1rem !important;
    color: #bfbfbf !important;
  }
}

.ant-btn.ant-btn-primary {
  border-color: $blue-text-color !important;
  background-color: $blue-text-color !important;

  &:hover {
    background-color: $button-hover-color !important;
    border: 1px solid $button-hover-color !important;
  }
}

.p-multiselect-label {
  max-width: 220px;
  overflow: unset !important;
  text-overflow: unset !important;
}

.ant-drawer-header {
  background: $grey-background-color !important;
}

.new-template-popup-wrapper {
  z-index: 10001;
}

.ant-popconfirm {
  z-index: 1070;
}

.activeFilter path {
  fill: $blue-dropdown !important;
}

.hovered-row-calendar .react-datepicker__week:hover {
  background-color: #EFEFEF;
}


div::-webkit-scrollbar {
  width: 10px !important;
  height: 12px !important;
}


div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 140px transparent !important;
  border: none !important;
  background: #E5E5E5 !important;
  border-radius: 4px;
}

div::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3) !important;
  background-clip: content-box !important;
  border: 2px solid transparent !important;
  border-radius: 15px !important;
  scrollbar-width: 10px !important;
}
