$main-color: #0f3366;
$main-text-color: #f1f1f1;
$active-tab-color: #f78b23;
$error-color: #F54819;
$background-color: #fff;
$secondary-text-color: #072447;
$leeds-header-color: #072447;
$button-hover-color: #398DC4;
$grey-text-color: #545454;
$blue-text-color: #0070B9;
$border-bottom-color: #e8e8e8;
$box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
$grey-background-color: #f5f5f5;
$light-grey-background: #fafafa;
$light-green: #008000;
$border-color: #d9d9d9;
$notification-2: #FFA500;
$disable-text: #828282;
$link-text: #15589A;
$dark-blue-select: #274775;
$blue-dropdown: #4484DA;
$light-grey: #577094;
$grey-text-second: #838383;
$grey-text-menu: #595959;
$light-grey_border: #e5e5e5;
$light-grey-text: #b2b2b2;
$green-haze: #009E3D;
