@import '../../../constants/cssConstants.scss';

.buttonOrange {
  padding-top: 5px !important;
  border-radius: 4px !important;
  transition: background-color 0.2s;
  color: $main-text-color !important;
  background-color: #FFA500 !important;
  border-color: #FFA500 !important;

  &:hover {
    opacity: 0.8;
  }
}

.buttonBlue {
  padding-top: 5px !important;
  border-radius: 4px !important;
  transition: background-color 0.2s;
  color: $main-text-color !important;
  background-color: #0070B9 !important;
  border-color: #0070B9 !important;

  &:hover {
    opacity: 0.8;
  }
}
